import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom"
import {
    AdminPage,
    LoginPage,
} from "./pages"
import MainPage from './Main.js'

export default function MainRoutes(){
  return (
    <Routes>
      <Route path="/*" element={<MainPage />} />
    </Routes>
  )
}