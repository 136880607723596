import React, { useEffect , useState, useRef, useContext  } from 'react'
import { Carousel } from 'rsuite';
import Wrapper from "../UI/Wrapper/Wrapper";
import Title from "../UI/Title/Title";
import Subtitle from "../UI/Subtitle/Subtitle";
import {
    Box,
    Typography
} from '@mui/material';
import { SectionRefsContext } from '../../contex/SectionRefsProvider';
// Images
import bg_1 from '../../assets/images/prolepsis/slider-1.png';
import bg_2 from '../../assets/images/bg_2.jpeg';
import bg_3 from '../../assets/images/bg_3.jpeg';
import bg_4 from '../../assets/images/bg_4.jpeg';
//css
import './HeroDetail.css';


const HeroRsuiteComponent = () => {
    const { section0Ref } = useContext(SectionRefsContext);
    return(
        <Box ref={section0Ref}>
            <Carousel 
                autoplay 
                shape={"dot"} 
                autoplayInterval={60000}
                style={{
                    height:'100vh'
                }}
            >
                <Box sx={{position:'relative'}}>
                    <Box 
                        style={{
                            position:'absolute',
                            bottom:0,
                            left:0,
                            backgroundColor:'rgba(0, 0, 0, 0.3)',
                            height:'100%',
                            width:'100%'
                        }}
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-end"}
                    >
                        <Box 
                            sx={{
                                width:{ xs: '100vw', sm: '100vw', md: '100vw', lg: '40vw', xl: '40vw' },
                                padding:{ xs: 5, sm: 5, md: 10, lg: 20, xl: 20 } 
                            }}
                        >
                            <Typography gutterBottom variant="h3" component="div" textAlign={"left"} sx={{color:'#fff'}} ml={1}>
                                Title
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div" textAlign={"left"} sx={{color:'#fff'}} ml={1}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </Typography>
                        </Box>
                    </Box>
                    <img 
                        src={bg_1} 
                        style={{
                            width:'100%',
                            height:'100%',
                            objectFit:'cover',
                        }} 
                    />
                </Box>
                <Box sx={{position:'relative'}}>
                    <Box 
                        style={{
                            position:'absolute',
                            bottom:0,
                            left:0,
                            backgroundColor:'rgba(0, 0, 0, 0.3)',
                            height:'100%',
                            width:'100%'
                        }}
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-end"}
                    >
                        <Box 
                            sx={{
                                width:{ xs: '100vw', sm: '100vw', md: '100vw', lg: '40vw', xl: '40vw' },
                                padding:{ xs: 5, sm: 5, md: 10, lg: 20, xl: 20 } 
                            }}
                        >
                            <Typography gutterBottom variant="h3" component="div" textAlign={"left"} sx={{color:'#fff'}} ml={1}>
                                Title
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div" textAlign={"left"} sx={{color:'#fff'}} ml={1}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </Typography>
                        </Box>
                    </Box>
                    <img 
                        src={`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/img-20180306-wa0029-3.jpg?w=1024`} 
                        style={{
                            width:'100%',
                            height:'100%',
                            objectFit:'cover',
                        }} 
                    />
                </Box>
                <Box sx={{position:'relative'}}>
                    <Box 
                        style={{
                            position:'absolute',
                            bottom:0,
                            left:0,
                            backgroundColor:'rgba(0, 0, 0, 0.3)',
                            height:'100%',
                            width:'100%'
                        }}
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-end"}
                    >
                        <Box 
                            sx={{
                                width:{ xs: '100vw', sm: '100vw', md: '100vw', lg: '40vw', xl: '40vw' },
                                padding:{ xs: 5, sm: 5, md: 10, lg: 20, xl: 20 } 
                            }}
                        >
                            <Typography gutterBottom variant="h3" component="div" textAlign={"left"} sx={{color:'#fff'}} ml={1}>
                                Title
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div" textAlign={"left"} sx={{color:'#fff'}} ml={1}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </Typography>
                        </Box>
                    </Box>
                    <img 
                        src={bg_3} 
                        style={{
                            width:'100%',
                            height:'100%',
                            objectFit:'cover',
                        }} 
                    />
                </Box>
                <Box sx={{position:'relative'}}>
                    <Box 
                        style={{
                            position:'absolute',
                            bottom:0,
                            left:0,
                            backgroundColor:'rgba(0, 0, 0, 0.3)',
                            height:'100%',
                            width:'100%'
                        }}
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-end"}
                    >
                        <Box 
                            sx={{
                                width:{ xs: '100vw', sm: '100vw', md: '100vw', lg: '40vw', xl: '40vw' },
                                padding:{ xs: 5, sm: 5, md: 10, lg: 20, xl: 20 } 
                            }}
                        >
                            <Typography gutterBottom variant="h3" component="div" textAlign={"left"} sx={{color:'#fff'}} ml={1}>
                                Title
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div" textAlign={"left"} sx={{color:'#fff'}} ml={1}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </Typography>
                        </Box>
                    </Box>
                    <img 
                        src={bg_4} 
                        style={{
                            width:'100%',
                            height:'100%',
                            objectFit:'cover',
                        }} 
                    />
                </Box>
            </Carousel>
        </Box>
    )
}

export default HeroRsuiteComponent;