import React, {useEffect,useState,useContext} from 'react'
import {
    Container,
    Box,
    Typography,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { SectionRefsContext } from '../../contex/SectionRefsProvider';

const NavHeaderComponent = () => {
    const { section0Ref, section1Ref, section2Ref, section3Ref } = useContext(SectionRefsContext);
    const [dreawerIsOpen,setDrawerIsOpen] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0);
    const Navigate = useNavigate()
    const [nav,setNav] = useState(null)

    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY;
            setScrollPosition(position);
        };
    
        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array ensures the effect runs only once

    const scrollToSection = (sectionRef) => {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    
    return( 
        <Box display={"flex"} sx={{backgroundColor:scrollPosition > (window.innerHeight-10) ?'rgba(0, 57, 132, 1)':window.location.pathname!="/"?'rgba(0, 57, 132, 1)':'rgba(0, 0, 0, 0.3)'}}>
            <Container maxWidth="xl">
                <Box display={"flex"} sx={{height:'5vh'}}>
                    <Box 
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                            width:'100%',
                            display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }
                        }}
                    >
                        <Box 
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={'start'}
                            alignItems={"center"}
                        >
                            <Box p={1} mr={3} sx={{cursor:'pointer'}} onClick={()=>{setNav(null);scrollToSection(section0Ref);}}>
                                <Typography variant="h6" sx={{color:'#ffffff'}}>
                                    PROLEPSIS
                                </Typography>
                            </Box>
                        </Box>
                        <Box 
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={'start'}
                            alignItems={"center"}
                        >
                            <Box 
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                            >
                                <Box 
                                    p={1} 
                                    sx={{cursor:'pointer',color:nav=="Our Mission"?'#00b7ff':'#ffffff'}}
                                    onClick={()=>{setNav("Our Mission");scrollToSection(section1Ref);}}
                                >
                                    <Typography data-translate-nav-header>
                                        Our Mission
                                    </Typography>
                                </Box>
                                <Box 
                                    p={1} 
                                    sx={{cursor:'pointer',color:nav=="About Us"?'#00b7ff':'#ffffff'}}
                                    onClick={()=>{setNav("About Us");scrollToSection(section2Ref);}}
                                >
                                    <Typography data-translate-nav-header>
                                        About Us
                                    </Typography>
                                </Box>
                                <Box 
                                    p={1} 
                                    sx={{cursor:'pointer',color:nav=="Our Services"?'#00b7ff':'#ffffff'}}
                                    onClick={()=>{setNav("Our Services");scrollToSection(section3Ref);}}
                                >
                                    <Typography data-translate-nav-header>
                                        Our Services
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box 
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                            width:'100%',
                            display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none' }
                        }}
                    >
                        <Box p={1} mr={3} sx={{cursor:'pointer'}} onClick={()=>{setNav(null);scrollToSection(section0Ref);}}>
                            <Typography variant="h6" sx={{color:'#ffffff'}}>
                                PROLEPSIS
                            </Typography>
                        </Box>
                        <Box 
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={'flex-end'}
                            alignItems={"center"}
                            sx={{
                                width:'100%',
                            }}
                        >
                            <Box p={1} sx={{cursor:'pointer'}} onClick={()=>{setDrawerIsOpen(!dreawerIsOpen)}}>
                                <MenuIcon sx={{ color:'#fff', fontSize: 40 }} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <Drawer open={dreawerIsOpen} onClose={() => setDrawerIsOpen(!dreawerIsOpen)}>
                <Box sx={{ width: 250, height:'100%', backgroundColor:'#4f4f4f' }} role="presentation" onClick={() => setDrawerIsOpen(!dreawerIsOpen)}>
                    <List>
                        <ListItem disablePadding>
                            <Typography variant="h6" sx={{color:'#ffffff'}}>
                                PROLEPSIS
                            </Typography>
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem disablePadding onClick={()=>{setNav("Our Mission");scrollToSection(section1Ref);}}>
                            <Box 
                                p={1} 
                                sx={{cursor:'pointer',color:nav=="Our Mission"?'#00b7ff':'#ffffff'}}
                            >
                                <Typography data-translate-nav-header>
                                    Our Mission
                                </Typography>
                            </Box>
                        </ListItem>
                        <ListItem disablePadding onClick={()=>{setNav("About Us");scrollToSection(section2Ref);}}>
                            <Box 
                                p={1} 
                                sx={{cursor:'pointer',color:nav=="About Us"?'#00b7ff':'#ffffff'}}
                            >
                                <Typography data-translate-nav-header>
                                    About Us
                                </Typography>
                            </Box>
                        </ListItem>
                        <ListItem disablePadding onClick={()=>{setNav("Our Services");scrollToSection(section3Ref);}}>
                            <Box 
                                p={1} 
                                sx={{cursor:'pointer',color:nav=="Our Services"?'#00b7ff':'#ffffff'}}
                            >
                                <Typography data-translate-nav-header>
                                    Our Services
                                </Typography>
                            </Box>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </Box>
    )
}

export default NavHeaderComponent