import React, { useEffect , useState, useRef, useContext } from 'react'
import Slider from "react-slick";
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography
} from '@mui/material';
// Images
import bg_1 from '../../assets/images/bg_1.jpeg';
import { SectionRefsContext } from '../../contex/SectionRefsProvider';

function AboutUs() {
    const { section2Ref } = useContext(SectionRefsContext);
  return (
    <Box ref={section2Ref} sx={{backgroundColor:'#ffffff'}} py={5}>
      <Box sx={{backgroundColor:'#f5f5f5'}}>
        <Container maxWidth="xl">
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                flexWrap={'wrap'}
            >
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    sx={{
                        width:{ xs: '100%', sm: '100%', md: '100%', lg: `40%`, xl: `40%` }
                    }}
                    p={3}
                >
                    <Box>
                        <Box>
                            <Typography variant="h4" sx={{fontWeight:"bold"}}>About Us</Typography>
                        </Box>
                        <Box sx={{textAlign:'justify'}}>
                            <Typography variant="subtitle1">
                            PROLEPSIS introduces an innovative approach to healthcare. At Prolepsis Medical Center, we prioritize our clients' well-being, offering comprehensive healthcare services, insights, and support to help you maintain optimal health.
                            </Typography>
                            <Typography variant="subtitle1">
                            Good health involves self-care, balanced nutrition, regular exercise, and smart lifestyle choices. It also requires routine check-ups to detect potential health issues early, before symptoms appear.
                            </Typography>
                            <Typography variant="subtitle1">
                            With a strong emphasis on prevention and an unwavering commitment to care and reliability, we guide you through every step of the process—from initial assessment to detailed results reporting—delivering high-quality medical services that enhance your healthcare experience.
                            </Typography>
                            <Typography variant="subtitle1">
                            Our mission is to be your preferred healthcare partner. To achieve this, we have assembled a dedicated medical team, invested in advanced technological equipment, and adhered to strict European compliance and inspection standards.
                            </Typography>
                            <Typography variant="subtitle1">
                            “We strive to provide the most accessible, friendly, and dependable healthcare service in Indonesia.”
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width:{ xs: '100%', sm: '100%', md: '100%', lg: `60%`, xl: `60%` }
                    }}
                    p={3}
                >
                    <Box>
                        <img 
                            src={`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/service-a-1.jpg?w=1024`} 
                            style={{
                                width:'100%',
                                height:'100%',
                                objectFit:'cover',
                            }} 
                        />
                    </Box>
                </Box>
            </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default AboutUs;
