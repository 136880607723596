import React, { useEffect , useState, useRef, useContext } from 'react'
import Slider from "react-slick";
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography
} from '@mui/material';
// Images
import bg_2 from '../../assets/images/bg_2.jpeg';
import { SectionRefsContext } from '../../contex/SectionRefsProvider';

function OurMission() {
    const { section1Ref } = useContext(SectionRefsContext);
  return (
    <Box ref={section1Ref} sx={{backgroundColor:'#ffffff'}} py={5}>
      <Box sx={{backgroundColor:'#f5f5f5'}}>
        <Container maxWidth="xl">
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                flexWrap={'wrap'}
            >
                <Box
                    sx={{
                        width:{ xs: '100%', sm: '100%', md: '100%', lg: `60%`, xl: `60%` }
                    }}
                    p={3}
                >
                    <Box>
                        <img 
                            src={`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/service-c.jpg`}
                            style={{
                                width:'100%',
                                height:'100%',
                                objectFit:'cover',
                            }} 
                        />
                    </Box>
                </Box>
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    sx={{
                        width:{ xs: '100%', sm: '100%', md: '100%', lg: `40%`, xl: `40%` }
                    }}
                    p={3}
                >
                    <Box>
                        <Box>
                            <Typography variant="h4" sx={{fontWeight:"bold"}}>Our Mission</Typography>
                        </Box>
                        <Box sx={{textAlign:'justify'}}>
                            <Typography variant="subtitle1">
                            Our mission is to elevate awareness about health prevention and set the benchmark for the highest quality health services in Indonesia. We are dedicated to delivering care that is not only effective and accurate but also comfortable and efficient for our clients. 
                            </Typography>
                            <Typography variant="subtitle1">
                            We believe that achieving this goal requires the integration of state-of-the-art diagnostic technologies and the expertise of highly qualified professionals who are passionate about healthcare. Our team is committed to ongoing education and training to ensure they remain at the forefront of medical advancements and best practices. 
                            </Typography>
                            <Typography variant="subtitle1">
                            Furthermore, we strive to create a welcoming and supportive environment for our clients, recognizing that exceptional healthcare extends beyond medical treatments to include compassionate patient care. Our holistic approach is designed to meet the diverse needs of the communities we serve, promoting overall well-being and long-term health. 
                            </Typography>
                            <Typography variant="subtitle1">
                            By adhering to these principles, we aim to be recognized as the leading medical center in Indonesia, trusted for our unwavering dedication to excellence in health services.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default OurMission;
