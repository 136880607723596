import React from "react"
import { Routes, Route } from "react-router-dom"
import {
    DashboardPage,
    AboutUs,
    GlobalPromoAndNews,
    ContactUs,
    Careers,
    BisnisDetails,
    PromoAndNews,
    ProductGaleri,
    CardExperimental,
} from "../pages"

export default function MainRoutes(){
    return (
        <Routes>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
    )
}