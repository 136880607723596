import React, {useEffect,useState} from 'react'
import {
  Box,
  Typography
} from '@mui/material';
import HeroDetail from '../../components/HeroDetail/HeroDetail';
import OurMission from '../../components/OurMission/OurMission';
import AboutUs from '../../components/AboutUs/AboutUs';
import OurServices from '../../components/OurServices/OurServices';
//services//
import DynamicModuleManagementServices from '../../services/DynamicProsperityModuleManagement.service'

function DashboardPage() {

    const [isLoading, setIsLoading] = useState(false);

    const [pageData, setPageData] = useState([]);

    useEffect(() => {
        // getModuleData()
        window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
    }, []);

    const getModuleData = async () => {
        setIsLoading(true)
        try{
            let params = {
                orderData:'id',
                orderDirection:'ASC',
                table:'bisnis_category'
            }
            const data = await DynamicModuleManagementServices.getModule(params)
            if(data.data.length>0){
                let idSortData = [...data.data].filter((x) => x.sequence == 0).sort((a, b) => a.id - b.id)
                let sequanceSort = [...data.data].filter((x) => x.sequence != 0).sort((a, b) => a.sequence - b.sequence)
                console.warn(`check sort data`,{
                    idSortData:idSortData,
                    sequanceSort:sequanceSort
                });
                setPageData([...sequanceSort,...idSortData])
            }
        } catch(error){
            alert(`There is and error`)
            console.warn(`There is and error`, error);
        } finally{
            setIsLoading(false)
        }
    }
    
    return (
        <>
            <HeroDetail />
            <OurMission />
            <AboutUs />
            <OurServices />
        </>
    );
}

export default DashboardPage;
