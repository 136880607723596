import React, { useEffect , useState, useRef, useContext  } from 'react'
import Slider from "react-slick";
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import img_1 from '../../assets/images/fnb/food_1.png';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import { SectionRefsContext } from '../../contex/SectionRefsProvider';

function OurServices() {
    const { section3Ref } = useContext(SectionRefsContext);
    const mockData = [1,2,3,4,5]
    return (
        <Box ref={section3Ref} sx={{backgroundColor:'#ffffff'}} py={5}>
            <Box sx={{backgroundColor:'#f5f5f5'}} py={5}>
                <Container maxWidth="xl">
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Typography variant="h4" sx={{fontWeight:"bold"}}>Our Services</Typography>
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        flexWrap={'wrap'}
                    >
                        {
                            mockData.map((item)=>
                                <Box
                                    sx={{
                                        width:{ xs: '100%', sm: '100%', md: '100%', lg: `${100/3}%`, xl: `${100/3}%` }
                                    }}
                                    p={3}
                                >
                                    <Box>
                                        <ScienceOutlinedIcon sx={{ fontSize: 80, color:'rgba(0, 57, 132, 1)' }} />
                                    </Box>
                                    <Box>
                                        <Typography variant="h5" sx={{fontWeight:"bold"}}>Layanan {item}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="subtitle1">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        </Typography>
                                    </Box>
                                </Box>
                            )
                        }
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default OurServices;
